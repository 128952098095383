import { Outlet } from 'react-router-dom';
import SidePanel from '../side-panel/SidePanel';
import Navbar from '../navbar/Navbar';

interface UserProp {
    user: any;
}

function SideMenuLayout(user: UserProp) {
    return (
        <>
            {user.user ? <SidePanel /> : <Navbar />}
            <Outlet />
        </>
    );
}

export default SideMenuLayout;