import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ChatInterface from "./components/chat_interface/ChatInterface";
import LandingPage from "./pages/landing_page/LandingPage";
import Homepage from "./pages/homepage/Homepage";
import Signup from "./pages/signup/Signup";
import Signin from "./pages/signin/Signin";
import CareerAdaptModal from "./components/modal/CareerAdaptModal";
import { APPLICATION_PATHS, ROLES } from './constants';
import { getCurrentUserFromLocalStorage } from './services/authentication-service';
import { setUser } from './store/user';
import RequireAuth from "./components/RequireAuth";
import { useAppSelector } from "./store";

import "./App.scss";
import NotFound from "./pages/not-found/NotFound";
import SideMenuLayout from "./components/side-menu-layout/SideMenuLayout";

function App() {
    const dispatch = useDispatch();
    const user = useAppSelector(state => state.user.currentUser);

    useEffect(() => {
        dispatch(setUser(getCurrentUserFromLocalStorage()));
    }, [])

    return (
        <div id='app'>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path={APPLICATION_PATHS.landing} element={<LandingPage />} />
                    <Route element={<SideMenuLayout user={user}/>}>
                        {/* Public routes */}
                        <Route path={APPLICATION_PATHS.login} element={<Signin />} />
                        <Route path={APPLICATION_PATHS.register} element={<Signup />} />
                        {/* Admin and User routes */}
                        <Route element={<RequireAuth allowedRoles={[ROLES.admin, ROLES.user]} />}>
                            <Route path={APPLICATION_PATHS.chat} element={<ChatInterface />} />
                            <Route path={APPLICATION_PATHS.home} element={<Homepage />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
            <CareerAdaptModal />
        </div>
    );
}

export default App;
