import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/material/styles";
import { Alert, AlertColor, Stack, Box } from "@mui/material";
import Cookies from "js-cookie";
import { login } from "../../services/authentication-service";
import { APPLICATION_PATHS } from "../../constants";
import { useAppSelector } from "../../store";

import "./Signin.scss";

const WhiteTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "white",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
        color: "white",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
        },
        "&:hover fieldset": {
            borderColor: "white",
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
        },
    },
});

function Signin() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertEnabled, setAlertEnabled] = useState<boolean>(false);
    const user = useAppSelector(state => state.user.currentUser);

    useEffect(() => {
        if (user) {
            navigate(APPLICATION_PATHS.home);
        }
    }, [navigate, user])

    const handleEmailTextInputChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordTextInputChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleLogin = () => {
        if(email && password) {
            login(email, password)
                .then((response) => {
                    if (response.status === 200) {
                        Cookies.set("access_token", response.data.token);
                        navigate(APPLICATION_PATHS.home);
                    }
                })
                .catch(function (error) {
                    const message = error.response.data.detail;
                    setAlertSeverity("error");
                    setAlertMessage(message);
                    setAlertEnabled(true);
                });
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="signin-container">
            <Typography variant="h4" style={{ color: "white" }}>
                Sign in
            </Typography>
            <div className="signin-input-container">
                <Box className="signin-input-item" sx={{ width: '80%' }}>
                    <WhiteTextField
                        fullWidth
                        value={email}
                        onChange={handleEmailTextInputChange}
                        focused={true}
                        InputLabelProps={{
                            style: { color: "white" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon sx={{ color: "white" }} />
                                </InputAdornment>
                            ),
                        }}
                        label="email"
                        id="email"
                        placeholder="example@example.com"
                    />
                </Box>
                <Box className="signin-input-item" sx={{ width: '80%' }}>
                    <WhiteTextField
                        fullWidth
                        value={password}
                        onChange={handlePasswordTextInputChange}
                        type={showPassword ? "text" : "password"}
                        focused={true}
                        InputLabelProps={{
                            style: { color: "white" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon sx={{ color: "white" }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleTogglePasswordVisibility}
                                        onMouseDown={handleTogglePasswordVisibility}
                                        edge="end"
                                        sx={{ color: "white" }}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="password"
                        id="password"
                        placeholder="**********"
                    />
                </Box>
                <div className="signin-input-button">
                    <Button variant="contained" onClick={handleLogin}>
                        Signin{" "}
                    </Button>
                </div>
                {alertEnabled && (
                    <Stack>
                        <Alert severity={alertSeverity}>{alertMessage}</Alert>
                    </Stack>
                )}
                <div className="signup-input-redirect">
                    <Typography sx={{ color: "white" }}>
                        Don't have an account? <a href="/register">Sign up</a>
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default Signin;
