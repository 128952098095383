import "./HistoryConversation.scss"

const HistoryConversation= ({question}:{question:any})=>{

    const formatDate = (date:any)=>{
        let rawDate = new Date(date)
        return rawDate.toLocaleDateString("en-GB")
    }
    return(
        <div className="history-conversation">
            <div className="history-conversation__date">{formatDate(question?.created_at)}</div>
            <div className = "history-conversation__first-message">{question?.content}</div>
        </div>
    )
}

export default HistoryConversation