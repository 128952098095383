import axios from "axios";
import { authHeader } from "./auth-header";
import { REACT_APP_BACKEND_URL } from "../variables";
import { logout } from "./authentication-service";

const fetchData = async(setState: any, path: String)=>{
    const headers = authHeader()
        axios.get(REACT_APP_BACKEND_URL + path, headers ).then((res)=>{
            setState(res.data)
            console.log("succesfully fatched data")
        }).catch((err)=>{
            if (err.response.status === 403) {
                logout();
            }
            const message = err.response.data.message;
            console.log(message)
        })
}

export default fetchData