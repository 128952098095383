import React from 'react';
import { openModal, closeModal } from '../store/modal';
import {store} from "../store";

const dispatch = store.dispatch;

class ModalService {
  openConfirmation(body: string, yesCallback: () => void): void {
    dispatch(openModal({
      title: 'Confirm Operation',
      body: body,
      submitBtnText: 'Yes',
      cancelBtnText: 'No',
      submitCallback: yesCallback
    }));
  }

  openComponent(
    title: string,
    body: React.ReactNode,
    submitBtnText: string,
    cancelBtnText: string,
    submitCallBack: () => void
  ): void {
    dispatch(openModal({
      title: title,
      body: body,
      submitBtnText: submitBtnText,
      cancelBtnText: cancelBtnText,
      submitCallback: submitCallBack
    }));
  }

  close(): void {
    dispatch(closeModal());
  }
}

export default new ModalService();
