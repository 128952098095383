import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../store";
import { APPLICATION_PATHS } from "../constants";

const RequireAuth = ({allowedRoles} : any) => {
    const location = useLocation();
    const user = useAppSelector((state) => state.user.currentUser);

    return user?.roles?.find((role : string) => allowedRoles?.includes(role)) ? (
        <Outlet />
    ) : user?.email ? (
        <Navigate to={APPLICATION_PATHS.home} state={{ from: location }} replace />
    ) : (
        <Navigate to={APPLICATION_PATHS.login} state={{ from: location }} replace />
    );
};

export default RequireAuth;
