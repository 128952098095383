export const APPLICATION_PATHS = {
  landing: '/',
  home: '/home',
  chat: '/chat',
  login: '/login',
  register: '/register',
  questions: '/questions',
  jobDomains: '/job-domains',
  jobSubdomains: '/job-subdomains',
  jobTitles: '/job-titles',
};

export const ROLES = {
  user: 'user',
  admin: 'admin',
};
