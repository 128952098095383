import React from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { SidePanelEntryPayload } from './side-panel.model';
import SidePanelEntry from './SidePanelEntry';
import { APPLICATION_PATHS, ROLES } from '../../constants';
import { useAppSelector } from '../../store';
import { logout } from '../../services/authentication-service';
import { useNavigate } from 'react-router-dom';
import './SidePanel.scss';
import ModalService from '../../services/modal-service';

const ENTRIES: SidePanelEntryPayload[] = [
  {
    path: APPLICATION_PATHS.home,
    text: 'Home',
    icon: <HomeOutlinedIcon/>,
    adminOnly: false
  },
  {
    path: APPLICATION_PATHS.chat,
    text: 'Conversation',
    icon: <ForumOutlinedIcon/>,
    adminOnly: false
  },
  {
    path: APPLICATION_PATHS.questions,
    text: 'Questions',
    icon: <HelpOutlineOutlinedIcon/>,
    adminOnly: true
  }
];

function SidePanel() {

  const currentUser = useAppSelector(state => state.user.currentUser);
  const navigate = useNavigate();

  const logoutWithModal = ()=>{
    ModalService.openConfirmation("Are you sure you want to log out?", ()=>{
      logout()
      navigate("/login")
    })
    
  }

  const byUserRights = (entryPayload: SidePanelEntryPayload): boolean => {
    if (entryPayload.adminOnly) {
      return currentUser?.roles?.includes(ROLES.admin) || false;
    }
    return true;
  }

  return (
    <div className='side-panel'>
      <div className='side-panel__header'>
        Career Adapt
      </div>
      <div className='side-panel__body'>
        {ENTRIES
          .filter(byUserRights)
          .map(entryPayload =>
            <SidePanelEntry
              key={`side-panel-entry-${entryPayload.path}`}
              payload={entryPayload}
            />
          )}
      </div>
      <div className='side-panel__footer'>
        <span className='side-panel__footer__text'>
          {currentUser?.email || ''}
        </span>
        <LogoutOutlinedIcon
          className='side-panel__footer__icon'
          onClick={logoutWithModal}
        />
      </div>
    </div>
  );
}

export default SidePanel;