import React, { useEffect } from "react";
import "./Homepage.scss";
import { useState } from 'react'
import HistoryConversation from "../../components/homepage/HistoryConversation";
import fetchData from "../../services/fetchData";

function Homepage() {

    const [messages, setMessages] = useState<any[]>([])

    useEffect(() => {
        fetchData(setMessages, "/messages/")
    }, [])


    return (
        <div className="homepage-wrapper">
            <div className="homepage__content">
                <div className="homepage__content__title"></div>
                <div className="homepage__content__info">
                    <div className="homepage__content__questions">You can review your conversation history from here:
                        <div>{messages.map((element, i) => {
                            return <HistoryConversation question={element} key={element.id}></HistoryConversation>
                        })}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;