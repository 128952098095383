import { ChangeEvent, FormEvent, JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useRef, useState } from "react";
import { Alert, Button, CircularProgress, IconButton } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArticleIcon from '@mui/icons-material/Article';
import axios from "axios";
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import Markdown from 'markdown-to-jsx'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import "./ChatInterface.scss";
import { logout } from "../../services/authentication-service";

type Message = {
    id?: number;
    user_id?: number;
    content: string;
    is_user_message: boolean;
    document_id?: number;
    created_at?: string;
};

type DocumentListElement = {
    id: number;
    document_title: string;
}

type Document = {
    id: number;
    document_title: string;
    document_content: string;
}

function ChatInterface() {
    const [conversation, setConversation] = useState<Message[]>([]);
    const [isConversationLoading, setIsConversationLoading] = useState(true);
    const [message, setMessage] = useState<string>("");
    const [isSending, setIsSending] = useState(false);
    const conversationEndRef = useRef<HTMLDivElement | null>(null);
    const messageInputRef = useRef<HTMLTextAreaElement | null>(null);

    const [showDocument, setShowDocument] = useState<boolean>(false);
    const [documentList, setDocumentList] = useState<DocumentListElement[]>([]);
    const [currentDocument, setCurrentDocument] = useState<Document | null>(null);

    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('');

    useEffect(() => {
        fetchMessages();
    }, []);

    useEffect(() => {
        scrollToBottom();
        let document_id = conversation[conversation.length - 1]?.document_id
        // Handle last message document_id
        if (document_id) {
            // Fetch list of all documents
            fetchDocumentList().then(() => {
                fetchDocument(document_id!).then(() => {
                    setShowDocument(true);
                })
            })
        }

    }, [conversation]);

    useEffect(() => {
        if (currentDocument === null && documentList.length > 0) {
            fetchDocument(documentList[documentList.length - 1].id);
        }
    }, [documentList])

    useEffect(() => {
        if (messageInputRef.current) {
            messageInputRef.current.focus();
        }
    }, [conversation]);

    useEffect(() => {
        if (snackMessage) {
            setSnackOpen(true);
        }
    }, [snackMessage]);

    const generateJobsDocuments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents/jobs_document`, {
                headers: authHeader(),
            });

            if (response.status === 200) {
                setCurrentDocument(response.data);
            } else {
                setSnackOpen(false);
                setSnackMessage("There was an error generating the documents.");
            }
        } catch (error: any) {
            if (error.response === undefined || error.response.status === 403) {
                logout();
            }
            console.error("Error fetching jobs document:", error);
        }
    }

    const fetchDocumentList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents/me`, {
                headers: authHeader(),
            });

            if (response.status === 200) {
                setDocumentList(response.data);
                return response.data;
            } else {
                setSnackOpen(false);
                setSnackMessage("There was an error fetching the documents.");
            }

            return null;
        } catch (error: any) {
            if (error.response === undefined || error.response.status === 403) {
                logout();
            }
            console.error("Error fetching document list:", error);
        }
    }

    const fetchDocument = async (documnet_id: number) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/documents/${documnet_id}`, {
                headers: authHeader(),
            });

            if (response.status === 200) {
                setCurrentDocument(response.data);
            } else {
                setSnackOpen(false);
                setSnackMessage("There was an error fetching the documents.");
            }
        } catch (error: any) {
            if (error.response === undefined || error.response.status === 403) {
                logout();
            }
            console.error("Error fetching document:", error);
        }
    }

    const fetchMessages = async () => {
        try {
            setIsConversationLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages/`, {
                headers: authHeader(),
            });

            if (response.status === 200) {
                setConversation(response.data);
            } else {
                setSnackOpen(false);
                setSnackMessage("There was an error fetching the messages.");
            }
        } catch (error: any) {
            if (error.response === undefined || error.response.status === 403) {
                logout();
            }
            console.error("Error fetching messages:", error);
        } finally {
            setIsConversationLoading(false);
        }
    };

    const handleSubmitMessage = async (e: FormEvent) => {
        e.preventDefault();
        const current_message = message;
        if (current_message.trim() === "") {
            return;
        }

        const tempId = Date.now();
        const newMessage: Message = { id: tempId, is_user_message: true, content: current_message };

        const loadingMessage: Message = { id: tempId + 1, is_user_message: false, content: '' };

        setConversation([...conversation, newMessage, loadingMessage]);
        setMessage('Awaiting response...');
        setIsSending(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/messages/`,
                { content: current_message },
                { headers: authHeader() }
            );

            if (response.status === 200) {
                setConversation(conversation => [
                    ...conversation.filter(msg => msg.id !== tempId && msg.id !== tempId + 1),
                    newMessage,
                    ...response.data
                ]);
            } else {
                setSnackOpen(false);
                setSnackMessage("There was an error sending the message.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setConversation(conversation => conversation.filter(msg => msg.id !== tempId && msg.id !== tempId + 1));
        } finally {
            setMessage("");
            setIsSending(false);
        }
    };

    const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
        adjustTextareaHeight();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmitMessage(e as unknown as FormEvent);
        }
    };

    const adjustTextareaHeight = () => {
        const textarea = messageInputRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Adjust to max height
            scrollToBottom();
        }
    };

    const authHeader = () => {
        const token = Cookies.get("access_token");
        return {
            Authorization: `Bearer ${token}`,
        };
    };

    const scrollToBottom = () => {
        if (conversationEndRef.current) {
            conversationEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const hanldeClickExit = () => {
        setShowDocument(false);
    }

    const handleClickShowDocuments = () => {
        // In case the current document is null and there are documents available , set last document as the current document (title,content)
        fetchDocumentList().then((response) => {
            if (response != null) {
                setShowDocument(true);
            }
        })
    }

    const handleGetJobs = () => {
        generateJobsDocuments().then(() => {
            fetchDocumentList().then((response) => {
                if (response != null) {
                    setShowDocument(true);
                }
            })
        })
    }

    const hanldeClickArticle = (document_id: number) => {
        fetchDocument(document_id);
    }

    const handleSnackClose = () => {
        setSnackOpen(false);

        // Make the default message "" after a small period
        // handled this way so we don't nullify the message before closing the snackbar
        setTimeout(() => {
            setSnackMessage("");
        }, 100);
    };

    return (
        <div className="chat-layout">
            <div className="chat-interface-wrapper" style={{ width: showDocument ? '60%' : '100%' }}>
                <div className="chat-interface-document-button">
                    <Button variant="contained"
                        sx={{
                            'backgroundColor': 'transparent',
                            ':hover': {
                                bgcolor: '#dfced726',
                                color: 'white',
                            },
                        }}
                        onClick={handleClickShowDocuments}>
                        <div className="chat-interface-document-button-text">Show Documents</div>
                        <KeyboardArrowRightIcon />
                    </Button>
                </div>
                <span className="chat-interface__panel__title">Conversation</span>
                <div className="chat-interface__panel__content conversation">
                    {isConversationLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {conversation.map((message: Message) => (
                                message.is_user_message ? (
                                    <div key={`message-${message.id}`} className="message user-message display-linebreak">
                                        <div className="content answer">
                                            <span className="text">
                                                <Markdown options={{ wrapper: 'article' }}>
                                                    {message.content}
                                                </Markdown>
                                            </span>
                                        </div>
                                    </div>
                                ) : message.content ? (
                                    <div key={`message-${message.id}`} className="message assistant-message display-linebreak">
                                        <div className="content question">
                                            <span className="text">
                                                <Markdown options={{ wrapper: 'article' }}>
                                                    {message.content}
                                                </Markdown>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={`message-${message.id}`} className="message assistant-message display-linebreak">
                                        <div className="content question">
                                            <span className="text">
                                                <CircularProgress size={32} />
                                            </span>
                                        </div>
                                    </div>
                                )
                            ))}
                            <div ref={conversationEndRef}></div>
                        </>
                    )}
                </div>
                <div className="chat-interface__message_input_container">
                    <form
                        onSubmit={handleSubmitMessage}
                        className={`chat-interface__message_input_form ${isSending ? 'disabled' : ''}`}
                    >
                        <textarea
                            className="chat-interface__message_input"
                            value={message}
                            placeholder="Type your message..."
                            onChange={handleMessageChange}
                            onKeyDown={handleKeyDown}
                            disabled={isSending}
                            ref={messageInputRef}
                            style={{
                                resize: 'none'
                            }}
                        />
                        <button
                            className="chat-interface__message_input_button"
                            type="submit"
                            disabled={isSending}
                        >
                            {isSending ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                '↑'
                            )}
                        </button>
                    </form>
                </div>
            </div>
            {showDocument &&
                (<div className="chat-document-wrapper" style={{ width: '50%' }}>
                    <div className="chat-document-top-bar">
                        <h1 style={{ margin: "20px" }}>Documents</h1>
                        <Button variant="contained"
                            sx={{
                                'background-color': 'transparent',
                                ':hover': {
                                    bgcolor: '#dfced726',
                                    color: 'white',
                                },
                            }}
                            onClick={hanldeClickExit}>
                            <div className="chat-interface-document-button-text">Close Documents</div>
                            <HighlightOffIcon style={{ color: "white" }} />
                        </Button>
                    </div>
                    {documentList.length > 0 &&
                        (
                            <div className="chat-document-container">
                                <div className="chat-document-content">
                                    {currentDocument?.document_title && <div className="chat-document-content-title">{currentDocument.document_title}</div>}
                                    {currentDocument?.document_content && (
                                        <div className="chat-document-content-text display-linebreak">
                                            <Markdown options={{
                                                wrapper: 'article',
                                                overrides: {
                                                    a: {
                                                        props: {
                                                            target: '_blank',
                                                        },
                                                    }
                                                }
                                            }}>
                                                {currentDocument.document_content}
                                            </Markdown>
                                        </div>)
                                    }
                                </div>
                                <div className="chat-document-list">
                                    {documentList.map(function (document, index) {
                                        return (
                                            <div
                                                className="chat-document-item"
                                                key={`document-${index}`}
                                                onClick={() => hanldeClickArticle(document.id)}
                                            >
                                                <ArticleIcon className="icon-button" />
                                                <div
                                                    className="chat-document-item-button"
                                                    title={document.document_title}
                                                >
                                                    {document.document_title}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="chat-interface-get-jobs-button">
                                        <Button variant="contained"
                                            sx={{
                                                'background-color': 'transparent',
                                                ':hover': {
                                                    bgcolor: '#dfced726',
                                                    color: 'white',
                                                },
                                            }}
                                            onClick={handleGetJobs}>
                                            <div className="chat-interface-get-jobs-button-text">Generate new jobs document</div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}

                </div>)
            }
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert
                    onClose={handleSnackClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', backgroundColor: '#E57373', color: '#FFFFFF' }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ChatInterface;
