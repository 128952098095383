import React from 'react';
import { Box, Button, Modal } from '@mui/material';
import { useAppSelector } from '../../store';
import ModalService from '../../services/modal-service';
import './CareerAdaptModal.scss';

function CareerAdaptModal() {
  const isOpen = useAppSelector(state => state.modal.isOpen);
  const title = useAppSelector(state => state.modal.title);
  const body = useAppSelector(state => state.modal.body);
  const submitBtnText = useAppSelector(state => state.modal.submitBtnText);
  const submitCallback = useAppSelector(state => state.modal.submitCallback);
  const cancelBtnText = useAppSelector(state => state.modal.cancelBtnText);

  let textBody: string | undefined = undefined;
  let componentBody: React.ReactNode | undefined = undefined;
  if (typeof body === 'string') {
    textBody = body;
  } else {
    componentBody = body;
  }

  const onSubmit = () => {
    submitCallback?.();
    close();
  }

  const close = () => {
    ModalService.close();
  }

  return (
    <Modal
      open={ isOpen }
      onClose={ close }
      className='modal'
    >
      <Box className='modal__panel'>
        <div className='modal__title'>
          {title}
        </div>
        <>{
          textBody ?
            (
              <div className='modal__text-body'>
                {textBody}
              </div>
            ):
            componentBody
        }</>

        <div className='modal__footer'>
          <Button
            className='modal__footer__button'
            variant='contained'
            onClick={onSubmit}
          >
            {submitBtnText}
          </Button>
          <Button
            className='modal__footer__button'
            variant='contained'
            onClick={close}
          >
            {cancelBtnText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default CareerAdaptModal;
