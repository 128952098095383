import axios from "axios";
import Cookies from "js-cookie";
import { store}  from "../store";
import { setUser, removeUser } from "../store/user";
import { REACT_APP_BACKEND_URL } from "../variables";

const dispatch = store.dispatch;

export const register = (email: string, password: string) => {
  return axios.post(REACT_APP_BACKEND_URL + "/auth/register", {
    email,
    password,
  });
};

export const login = (email: string, password: string) => {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);

  return axios
    .post(REACT_APP_BACKEND_URL + '/auth/login', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        dispatch(setUser(response.data));
      }

      return response;
    });
};

export const logout = () => {
  Cookies.remove('access_token');
  localStorage.removeItem("user");
  dispatch(removeUser());
};

export const getCurrentUserFromLocalStorage = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};