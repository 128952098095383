import Button from "@mui/material/Button";
import "./LandingPage.scss";
import Navbar from "../../components/navbar/Navbar";
import { Diversity1Outlined } from "@mui/icons-material";

function LandingPage() {
    return (
        <>
            <Navbar />
            <div className="landing-page-container">
                <div className="landing-page-title">Career Adapt</div>
                <div className="landing-page-content">
                    <div className="landing-page-content-item">
                        <div>
                            Navigating career changes can be challenging, whether you’ve lost your job, AI has transformed your industry, or you’re simply ready for a new direction. That’s why we’re excited to introduce Career Adapt—a tool designed to help you seamlessly transition to your next career.
                        </div>
                    </div>
                    <div className="landing-page-content-item">
                        <div>
                            With Career Adapt, you’ll get personalized guidance, skill assessments, and resources tailored to your unique situation, empowering you to take the next step with confidence.
                        </div>
                    </div>
                    <div className="landing-page-content-item">
                        <div>
                            Ready to explore new opportunities? Let’s get started with Career Adapt today!
                        </div>
                    </div>
                    <div className="landing-page-content-item">
                        <div>
                            <Button href="/chat" variant="contained" style={{ maxWidth: '175px', maxHeight: '60px', minWidth: '175px', minHeight: '60px', fontSize: '20px' }}>
                                {"Try it now "}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingPage;
