import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '../../components/navbar/Navbar';
import "./NotFound.scss"

function NotFound() {
    const [countdown, setCountdown] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(interval);
            navigate('/'); // Redirect to the main page
        }

        return () => clearInterval(interval); // Cleanup on unmount
    }, [countdown, navigate]);

    return (
        <>
            <Navbar />
            <div className="not-found-container">
                <div className="not-found-content">
                    <h1>404</h1>
                    <p>Sorry , page not found</p>
                    <p>The link you followed is probably broken or the page has been removed</p>
                    <p>You will be redirected to the main page in {countdown} seconds</p>
                </div>
            </div>
        </>
    );
}

export default NotFound;
