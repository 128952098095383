import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Alert, Stack, AlertColor, Box } from "@mui/material";
import { APPLICATION_PATHS } from "../../constants";
import { register } from "../../services/authentication-service";
import { useAppSelector } from "../../store";
import "./Signup.scss";

const WhiteTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "white",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-input": {
        color: "white",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
        },
        "&:hover fieldset": {
            borderColor: "white",
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
        },
    },
});

function Signup() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertEnabled, setAlertEnabled] = useState<boolean>(false);
    const user = useAppSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (user) {
            navigate(APPLICATION_PATHS.home);
        }
    }, [navigate, user]);

    const handleEmailTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setEmail(event.target.value);
    };

    const handlePasswordTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordTextInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setConfirmPassword(event.target.value);
    };

    const validateEmail = (email: string): boolean => {
        // Basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePassword = (password: string): boolean => {
        // Password should be at least 6 characters and contain at least one number
        const regex = /^(?=.*[0-9])[A-Za-z\d@$!%*?&]{6,}$/;
        return regex.test(password);
    };

    const handleCreateAccount = async () => {
        // Validation checks
        if (!validateEmail(email)) {
            setAlertSeverity("error");
            setAlertMessage("Please enter a valid email address.");
            setAlertEnabled(true);
            return;
        }

        if (!validatePassword(password)) {
            setAlertSeverity("error");
            setAlertMessage(
                "Password must be at least 6 characters long and contain at least one number."
            );
            setAlertEnabled(true);
            return;
        }

        if (password !== confirmPassword) {
            setAlertSeverity("error");
            setAlertMessage("Passwords do not match.");
            setAlertEnabled(true);
            return;
        }

        // Call register API service
        register(email, password)
            .then((response) => {
                if (response.status === 200) {
                    const message = response.data.message;
                    setAlertSeverity("success");
                    setAlertMessage("User created successfully. Please login.");
                    setAlertEnabled(true);
                }
            })
            .catch(function (error) {
                const message =
                    error.response?.data?.message ||
                    "Something went wrong. Please try again later.";
                setAlertSeverity("error");
                setAlertMessage(message);
                setAlertEnabled(true);
            });
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="signup-container">
            <Typography variant="h4" style={{ color: "white" }}>
                Create your account
            </Typography>
            <div className="signup-input-container">
                <Box className="signup-input-item" sx={{ width: '80%' }}>
                    <WhiteTextField
                        fullWidth
                        value={email}
                        onChange={handleEmailTextInputChange}
                        focused={true}
                        InputLabelProps={{
                            style: { color: "white" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon sx={{ color: "white" }} />
                                </InputAdornment>
                            ),
                        }}
                        label="Email"
                        id="email"
                        placeholder="example@example.com"
                    />
                </Box>
                <Box className="signup-input-item" sx={{ width: '80%' }}>
                    <WhiteTextField
                        fullWidth
                        value={password}
                        onChange={handlePasswordTextInputChange}
                        type={showPassword ? "text" : "password"}
                        focused={true}
                        InputLabelProps={{
                            style: { color: "white" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon sx={{ color: "white" }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleTogglePasswordVisibility}
                                        onMouseDown={handleTogglePasswordVisibility}
                                        edge="end"
                                        sx={{ color: "white" }}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Password"
                        id="password"
                        placeholder="**********"
                    />
                </Box>
                <Box className="signup-input-item" sx={{ width: '80%' }}>
                    <WhiteTextField
                        fullWidth
                        value={confirmPassword}
                        onChange={handleConfirmPasswordTextInputChange}
                        type={showConfirmPassword ? "text" : "password"}
                        focused={true}
                        InputLabelProps={{
                            style: { color: "white" },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <KeyIcon sx={{ color: "white" }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={handleToggleConfirmPasswordVisibility}
                                        onMouseDown={handleToggleConfirmPasswordVisibility}
                                        edge="end"
                                        sx={{ color: "white" }}
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Confirm Password"
                        id="confirmPassword"
                        placeholder="**********"
                    />
                </Box>
                <div className="signup-input-button">
                    <Button variant="contained" onClick={handleCreateAccount}>
                        Create my account
                    </Button>
                </div>
                {alertEnabled && (
                    <Stack>
                        <Alert severity={alertSeverity}>{alertMessage}</Alert>
                    </Stack>
                )}
                <div className="signup-input-redirect">
                    <Typography sx={{ color: "white" }}>
                        Already have an account? <a href="/login">Sign in</a>
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default Signup;
