import Cookies from 'js-cookie';

export type AuthHeader = {
    headers: {
      Authorization: string;
    };
  };

export const authHeader = (): AuthHeader => {
    const token = Cookies.get("access_token");

    return {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
};