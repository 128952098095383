import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';

export interface OpenModalPayload {
  title: string,
  body: string | React.ReactNode,
  submitBtnText: string,
  cancelBtnText: string,
  submitCallback: () => void
}

interface ModalSliceState {
  isOpen: boolean,
  title?: string,
  body?: string | React.ReactNode,
  submitBtnText?: string,
  cancelBtnText?: string,
  submitCallback?: () => void
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    title: undefined,
    body: undefined,
    submitBtnText: undefined,
    cancelBtnText: undefined,
    submitCallback: undefined
  } as ModalSliceState,
  reducers: {
    openModal: (state: Draft<ModalSliceState>, action: PayloadAction<OpenModalPayload>) => {
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.submitBtnText = action.payload.submitBtnText;
      state.cancelBtnText = action.payload.cancelBtnText;
      state.submitCallback = action.payload.submitCallback;
      state.isOpen = true;
    },
    closeModal: (state: Draft<ModalSliceState>) => {
      state.isOpen = false;
      state.title = undefined;
      state.body = undefined;
      state.submitBtnText = undefined;
      state.cancelBtnText = undefined;
      state.submitCallback = undefined;
    }
  }
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
