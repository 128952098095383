import * as React from 'react';
import { APPLICATION_PATHS } from '../../constants';

import './Navbar.scss';

function Navbar() {

    return (
        <div className="nav-bar-container">
            <div className="nav-bar-left-container">
                <div className="nav-item">
                    <a href={APPLICATION_PATHS.landing}>Home</a>
                </div>
            </div>
            <div className="nav-bar-right-container">
                <div className="nav-item">
                    <a href={APPLICATION_PATHS.login}>Login</a>
                </div>
                <div className="nav-item">
                    <a href={APPLICATION_PATHS.register}>Signup</a>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
