import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface User {
  name: string,
  email: string,
  roles: string[],
  token: string
}

interface UserSliceState {
  currentUser: User | null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null
  } as UserSliceState,
  reducers: {
    setUser: (state: Draft<UserSliceState>, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    removeUser: (state: Draft<UserSliceState>) => {
      state.currentUser = null;
    }
  }
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
