import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SidePanelEntryProps, SidePanelSubEntryPayload } from './side-panel.model';

const DEFAULT_STYLE = 'side-panel__body__entry';
const SELECTED_STYLE = `${DEFAULT_STYLE} selected`;
const DEFAULT_SUB_ENTRY_STYLE = 'side-panel__body__entry__sub';
const SELECTED_SUB_ENTRY_STYLE = `${DEFAULT_SUB_ENTRY_STYLE} selected`;

function SidePanelEntry({ payload }: SidePanelEntryProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    const hasCurrentPath = location.pathname === payload.path;
    const subEntryHasCurrentPath =
      payload.subEntries?.find(subEntry => location.pathname === subEntry.path) !== undefined;
    setIsSelected(hasCurrentPath || subEntryHasCurrentPath);
  }, [location]);

  const onEntryClick = () => {
    if (!isSelected) {
      navigate(payload.path);
    }
  }

  const onSubEntryClick = (entry: SidePanelSubEntryPayload) => {
    if (location.pathname !== entry.path) {
      navigate(entry.path);
    }
  }

  return (
    <div
      className={isSelected ? SELECTED_STYLE : DEFAULT_STYLE}
    >
      <div
        className='side-panel__body__entry__main'
        onClick={onEntryClick}
      >
        {payload.icon}
        {payload.text}
      </div>
      {isSelected ?
        (payload.subEntries?.map(subEntry => (
          <div
            key={`side-panel-sub-entry-${subEntry.path}`}
            className={location.pathname === subEntry.path ? SELECTED_SUB_ENTRY_STYLE : DEFAULT_SUB_ENTRY_STYLE}
            onClick={() => onSubEntryClick(subEntry)}
          >
            {subEntry.text}
          </div>))):
        null
      }
    </div>
  );
}

export default SidePanelEntry;
